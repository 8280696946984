import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanfung/Documents/Projects/Code/gatsby-portfolio/src/templates/portfolio.js";
import { graphql } from "gatsby";
export const query = graphql`
  query {
    sva1: file(relativePath: { eq: "SVA/sva-01.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva2: file(relativePath: { eq: "SVA/sva-02.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva3: file(relativePath: { eq: "SVA/sva-03.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva4: file(relativePath: { eq: "SVA/sva-04.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva5: file(relativePath: { eq: "SVA/sva-05.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva6: file(relativePath: { eq: "SVA/sva-06.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva7: file(relativePath: { eq: "SVA/sva-07.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva8: file(relativePath: { eq: "SVA/sva-08.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva9: file(relativePath: { eq: "SVA/sva-09.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva10: file(relativePath: { eq: "SVA/sva-10.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva11: file(relativePath: { eq: "SVA/sva-11.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva12: file(relativePath: { eq: "SVA/sva-12.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva13: file(relativePath: { eq: "SVA/sva-13.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sva14: file(relativePath: { eq: "SVA/sva-14.png"}) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridTwo = makeShortcode("GridTwo");
const ImageContainer = makeShortcode("ImageContainer");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <GridTwo mdxType="GridTwo">
      <ImageContainer classes="large" fluid={props.data.sva1.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.sva2.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.sva3.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The default view for Product Try-On gives users a clean interface to interact with different color swatches. The swatches place the selected makeup color and style on the user with face tracking technology and augemented reality.</p>
      <ImageContainer classes="small" fluid={props.data.sva4.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.sva5.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">As the app evolved and new features were added, our team had to rethink how the customers would interact with the app. Instead of redesigning the UI to get everything to fit, we decided to introduce nested navigational elements. In the above comps, selecting eye shadow allows the user to have more functionality over the placement of the makeup. Color Swatches were then nested inside of Color Palettes. The reasoning for this was to match the actual product sold by Sephora.</p>
      <ImageContainer classes="small right" fluid={props.data.sva6.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.sva7.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.sva8.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">As customers navigate through the app and try on makeup products - they have the ability to add it to their cart. Once added the integrated on screen cart is quickly accessible from the drop down bar located under the main navigation.</p>
      <ImageContainer classes="large" fluid={props.data.sva9.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The app is made up of three sections - the above section is the "try and buy" eCommerce portion of the app and the remaining two sections make up the thought leadership sections. The first of these two sections is called Looks. This section houses makeup combinations or Looks for a variety of events. The content is created by Sephora In-Store Beauty Specialists.</p>
      <ImageContainer classes="small right" fluid={props.data.sva10.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.sva11.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small space-me right blog-text">The last section of the app cements Sephora's brand as a trusted leader in its industry through tutorials from professional makeup artists.</p>
      <ImageContainer classes="small" fluid={props.data.sva12.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small" fluid={props.data.sva13.childImageSharp.fluid} mdxType="ImageContainer" />
      <ImageContainer classes="small right" fluid={props.data.sva14.childImageSharp.fluid} mdxType="ImageContainer" />
      <p className="small right blog-text">Please contact me if you'd like to learn more about this project.</p>
    </GridTwo>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      